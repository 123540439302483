import React from "react";
import logo from "../assets/img/header-logo.svg";
import arrow from "../assets/img/header-arrow.svg";
import "../assets/css/Header.css";

const Header = ({ contactRef }) => {
  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="header">
      <div className="header-component">
        <div className="header-sub-component-1">
          <div className="header-content-1">
            Best & Fastest <span>Delivery</span> in Your Place
          </div>
          <div className="header-content-2">
            We’re excited to bring our passion for food to your next event. Let
            KLM Catering make your occasion extraordinary.
          </div>
          <div className="header-button">
            <button onClick={scrollToContact}>
              Book Now
              <img src={arrow} alt="Arrow" />
            </button>
          </div>
        </div>
        <div className="header-sub-component-2">
          <img src={logo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Header;
