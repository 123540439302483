import React from "react";
import logo from "../assets/img/logo.svg";
import img1 from "../assets/img/about-1.png";
import img2 from "../assets/img/about-2.png";
import img3 from "../assets/img/about-3.svg";
import img4 from "../assets/img/about-4.svg";
import img5 from "../assets/img/about-5.svg";
// import since_img from "../assets/img/about-since.svg";
import "../assets/css/AboutUs.css";

const AboutUs = () => {
  return (
    <>
      <div className="about-us">
        <p>About Us</p>
        <div className="about-logo">
          <img src={logo} alt="" />
        </div>
        <div className="about-component">
          <div className="about-content">
            <div className="about-content-1">
              <img src={img1} alt="item-1" />
            </div>
            <div className="about-content-1">
              <img src={img2} alt="item-2" />
              <div className="about-since">
                <div className="since-circle">
                  <p>Since 2024</p>
                </div>
              </div>
            </div>
          </div>
          <div className="about-description">
            <p>Elevating Your Events. With Exceptional Food.</p>
            <div className="about-content-2">
              <img src={img3} alt="Excellence" />
              <img src={img4} alt="Fresh" />
              <img src={img5} alt="Quality" />
            </div>
          </div>
        </div>
        <div className="about-component-2">
          <p>
            Welcome to KLM Catering—where creativity meets flavor. We specialize
            in turning your events into memorable moments with dishes that
            delight and inspire.
          </p>
          <p>
            At KLM Catering, we believe that great food is the key to an
            unforgettable experience. Our team blends fresh, high-quality
            ingredients with innovative techniques to create menus that are as
            unique as your event. Whether you’re hosting a cozy gathering or a
            grand celebration, we craft each dish with care and creativity.
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
