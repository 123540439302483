import React from "react";
import whatsapp from "../assets/img/whatsapp.svg";
import mail from "../assets/img/mail.svg";
import place_order from "../assets/img/about-place.svg";
import "../assets/css/ContactDetails.css";

const ContactDetails = ({ contactRef }) => {
  return (
    <>
      <div className="contacts" ref={contactRef}>
        <p>For Booking Contact us</p>
        <div className="contact-buttons">
          <a
            href="https://wa.me/16478364989"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button>
              <img src={whatsapp} alt="whatsapp" />
              +1 (647) 836-4989
            </button>
          </a>
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&to=klmcaterings18@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="mail-button">
              <img src={mail} alt="E-Mail" />
              klmcaterings18@gmail.com
            </button>
          </a>
        </div>
        <div className="contact-note">
          <p>Note :</p>
          <div className="contact-note-content">
            <ul>
              <li>Free delivery for orders within 1 km.</li>
              <li>For deliveries beyond 1 km, a C$5 fee will apply.</li>
            </ul>
          </div>
        </div>
        <div className="contact-place">
          <img src={place_order} alt="" />
        </div>
      </div>
    </>
  );
};

export default ContactDetails;
