import React from "react";
import img from "../assets/img/recipe.png";
import img2 from "../assets/img/high-quality.svg";
import dollor from "../assets/img/dollor.svg";
import "../assets/css/OurRecipe.css";

const OurRecipe = ({ contactRef }) => {
  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <div className="our-recipe">
        <p>Our's Recipe</p>
        <div className="recipe-component">
          <div className="recipe-content">
            <img src={img} alt="Our Recipe" />
            <div className="quality-content">
              <label>KLM Veg Meals</label>
              <span>:</span>
              <p>Rice, Sambar, Rasam, Kootu, Appalam, Yogurt</p>
            </div>
            <div className="quality-price">
              <button onClick={scrollToContact}>Order Now</button>
              <div className="quality-price-content">
                <img src={dollor} alt="" />
                <p>379.98 / 20 Person Combo</p>
              </div>
            </div>
          </div>
          <div className="recipe-quality">
            <img src={img2} alt="High Quality" />
          </div>
        </div>
      </div>
    </>
  );
};

export default OurRecipe;
