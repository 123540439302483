import React from "react";
import logo from "../assets/img/logo.svg";
import arrow from "../assets/img/header-arrow.svg";
import "../assets/css/Navbar.css";

const Navbar = ({ contactRef }) => {
  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <div className="navbar">
        <div className="navbar-component">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="navbar-button">
            <button onClick={scrollToContact}>
              Book Now
              <img src={arrow} alt="Arrow" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
