import React from "react";
import mouse from "../assets/img/mouse.svg";
import "../assets/css/Services.css";

const Services = () => {
  const service_list = [
    "Wedding",
    "House Warming",
    "Get Together",
    "Anniversary",
    "Family Meeting",
    "Engagement",
    "Birthday",
  ];

  const serviceItems = service_list.map((service, index) => (
    <span className="service-item" key={index}>
      &#9679; {service}
    </span>
  ));

  return (
    <>
      <div className="services">
        <div className="service-container">
          <div className="services-wrapper">
            <div className="services-list">
              {serviceItems}
              {serviceItems}
            </div>
          </div>
        </div>
        <div class="hero_mouse-scroll">
          <div class="arrow-container">
            <div class="down-arrow"></div>
            <img src={mouse} alt="Mouse-Scroll" class="mouse-image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
