import React from "react";
import img1 from "../assets/img/why-smile.png";
import img2 from "../assets/img/why-quality.png";
import img3 from "../assets/img/why-friendly.png";
import img4 from "../assets/img/why-service.png";
import "../assets/css/WhyChooseUs.css";

const WhyChooseUs = () => {
  return (
    <>
      <div className="why-choose-us">
        <p>Why Choose Us.</p>
        <div className="second-p">
          <p>Leave Your Guests Speechless With Our Fabulous Food!</p>
        </div>
        <div className="why-component">
          <div className="why-content">
            <div className="why-content-img">
              <img src={img1} alt="Authentic Taste" />
            </div>
            <p>Authentic Taste</p>
            <p>
              Enjoy our traditional recipes, inspired by rich culinary heritage.
            </p>
          </div>
          <div className="why-content">
            <div className="why-content-img">
              <img src={img2} alt="Quality Food" />
            </div>
            <p>Quality Food</p>
            <p>
              We make sure that the food we provide is nutritious as well as
              tasty.
            </p>
          </div>
          <div className="why-content">
            <div className="why-content-img">
              <img src={img3} alt="Budget Friendly" />
            </div>
            <p>Budget Friendly</p>
            <p>
              The most of suppliers offer cooking options that can be customized
              to fit your budget.
            </p>
          </div>
          <div className="why-content">
            <div className="why-content-img">
              <img src={img4} alt="Service Excellence" />
            </div>
            <p>Service Excellence</p>
            <p>Outstanding service and unforgettable experiences...</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyChooseUs;
