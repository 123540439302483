import React from "react";
import logo from "../assets/img/footer-logo.svg";
import location from "../assets/img/location.svg";
import mail from "../assets/img/footer-mail.svg";
import ph_no from "../assets/img/footer-ph.svg";
import "../assets/css/Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="footer-component">
        <div className="footer-content">
          <img src={logo} alt="Logo" />
          <p>
            Let KLM Catering transform your special occasion into a masterpiece
            with our professional touch.
          </p>
        </div>
        <div className="footer-content-2">
          <span>Our Services</span>
          <ul>
            <li>Wedding</li>
            <li>House Warming</li>
            <li>Get Together</li>
            <li>Anniversary</li>
            <li>Family Meeting</li>
            <li>Engagement</li>
            <li>Birthday</li>
          </ul>
        </div>
        <div className="footer-content-3">
          <span>Contact Now</span>
          <div className="footer-contact">
            <div className="footer-contact-content">
              <img src={location} alt="Location" />
              <a
                href="https://google.com/maps/place/Kitchener,+ON,+Canada/@43.4304048,-80.558716,12z/data=!3m1!4b1!4m6!3m5!1s0x882bf48c03ee5105:0x9525f8e6df5f544b!8m2!3d43.4516395!4d-80.4925337!16zL20vMG1iZjQ?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>Kitchener Waterloo.</p>
              </a>
            </div>
            <div className="footer-contact-content">
              <img src={mail} alt="Mail" />
              <a
                href="https://mail.google.com/mail/?view=cm&fs=1&to=klmcaterings18@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>klmcaterings18@gmail.com</p>
              </a>
            </div>
            <div className="footer-contact-content">
              <img src={ph_no} alt="Phone Number" />
              <a href="tel:+16478364989">
                <p>+1 (647) 836-4989</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        © 2024 KLM Caterings All rights reserved
      </div>
    </footer>
  );
};

export default Footer;
