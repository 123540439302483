import React, { useRef } from "react";
import Navbar from "./components/Navbar.jsx";
import Header from "./components/Header.jsx";
import Services from "./components/Services.jsx";
import AboutUs from "./components/AboutUs.jsx";
import OurRecipe from "./components/OurRecipe.jsx";
import WhyChooseUs from "./components/WhyChooseUs.jsx";
import ContactDetails from "./components/ContactDetails.jsx";
import Footer from "./components/Footer.jsx";
import "./App.css";

function App() {
  const contactRef = useRef(null);
  return (
    <div className="App">
      <Navbar contactRef={contactRef} />
      <Header contactRef={contactRef} />
      <Services />
      <AboutUs />
      <OurRecipe contactRef={contactRef} />
      <WhyChooseUs />
      <ContactDetails contactRef={contactRef} />
      <Footer />
    </div>
  );
}

export default App;
